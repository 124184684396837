import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { Dropdown, DropdownProps, Icon, Popup } from "semantic-ui-react";

import CarrierFilterLabels from "./CarrierFilterLabels";
import { CarriersFilter } from "hooks/useCarriersFilter";
import { carrierSortOptions } from "shared/constants";
import CarrierFilterForm from "../CarrierFilterForm/CarrierFilterForm";
import { useGetCarriersOptions } from "hooks/useGetCarriersOptions";

import styles from "./CarrierFilterBar.module.scss";

export type CarrierFilterBarProps = {
  carriersFilter: CarriersFilter;
  setCarriersFilter: Dispatch<SetStateAction<CarriersFilter>>;
  resetQuickCapacity: () => void;
};

const CarrierFilterBar = (props: CarrierFilterBarProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const { data: filterFormOptions, isLoading: filterFormOptionsLoading } =
    useGetCarriersOptions();

  const { carriersFilter, setCarriersFilter, resetQuickCapacity } = props;
  const onSortChange = useCallback(
    (_: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      const [sortField, dir] = String(value).split(",");

      if (sortField && (dir === "ASC" || dir === "DESC")) {
        resetQuickCapacity();
        setCarriersFilter({
          ...carriersFilter,
          sortField,
          dir,
        });
      }
    },
    [carriersFilter, setCarriersFilter, resetQuickCapacity]
  );

  return (
    <div className={styles.carrierFilterBar}>
      <CarrierFilterLabels
        carriersFilter={carriersFilter}
        setCarriersFilter={setCarriersFilter}
        resetQuickCapacity={resetQuickCapacity}
      />

      <div className={styles.carrierFilterSection}>
        <span className={styles.filterTitle}>Sort:</span>

        <Dropdown
          options={
            carriersFilter.capacityOrigin
              ? carrierSortOptions
              : carrierSortOptions.map((option) => ({
                  ...option,
                  disabled: option.value.includes("OriginDistance"),
                }))
          }
          direction="left"
          value={`${carriersFilter.sortField},${carriersFilter.dir}`}
          onChange={onSortChange}
        />

        <Popup
          position="bottom right"
          on="click"
          open={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          offset={[6, 0]}
          trigger={
            <Icon
              circular
              link
              fitted
              inverted={!isPopupOpen}
              name="filter"
              color="blue"
              title={`${isPopupOpen ? "Hide" : "Show"} additional filters`}
              onClick={() => setIsPopupOpen((isOpen) => !isOpen)}
            />
          }
        >
          <Popup.Content>
            <CarrierFilterForm
              carriersFilter={carriersFilter}
              setCarriersFilter={setCarriersFilter}
              closePopup={() => setIsPopupOpen(false)}
              filterFormOptions={filterFormOptions}
              filterFormOptionsLoading={filterFormOptionsLoading}
              resetQuickCapacity={props.resetQuickCapacity}
            />
          </Popup.Content>
        </Popup>
      </div>
    </div>
  );
};

export default CarrierFilterBar;
