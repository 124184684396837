import * as actionTypes from '../constants/actionTypes';
import { updateObject } from 'shared/utils/utility';

interface State {
  userRoles: string | null;
}

interface Action {
  type: string;
  userRoles: string;
}

const initialState: State = {
  userRoles: null
}

const saveUserRoleStart = (state: State): State => state;
const saveUserRoleSuccess = (state: State, action: Action): State => {
  return updateObject(state, { userRoles: action.userRoles });
}

const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case actionTypes.SAVE_USER_ROLE_START: return saveUserRoleStart(state);
    case actionTypes.SAVE_USER_ROLE_SUCCESS: return saveUserRoleSuccess(state, action);

    default: return state;
  }
}

export default reducer;
