import * as actionTypes from '../constants/actionTypes';
import { Dispatch } from 'redux';

const saveUserRoleStart = () => {
  return { type: actionTypes.SAVE_USER_ROLE_START } as const;
};

const saveUserRoleSuccess = (userRoles: string) => {
  return { type: actionTypes.SAVE_USER_ROLE_SUCCESS, userRoles: userRoles } as const;
};

export const saveUserRoles = (userRoles: string) => {
  return (dispatch: Dispatch) => {
    dispatch(saveUserRoleStart());
    dispatch(saveUserRoleSuccess(userRoles));
  };
};
