import { Dispatch, SetStateAction } from "react";
import { Flag, FlagNameValues, Icon } from "semantic-ui-react";

import {
  CarriersFilter,
  getDefaultCarriersFilter,
} from "hooks/useCarriersFilter";
import CarrierFilterLabelPopup from "./CarrierFilterLabelPopup";
import { formatClassification } from "components/CarrierList/List/utils/carrierListUtilities";
import {
  defaultAccountOwners,
  statusToColorMap,
} from "../CarrierFilterForm/CarrierFilterForm";
import { useUsername } from "hooks/useUsername";

import styles from "./CarrierFilterBar.module.scss";

type CarrierFilterLabelsProps = {
  carriersFilter: CarriersFilter;
  setCarriersFilter: Dispatch<SetStateAction<CarriersFilter>>;
  resetQuickCapacity: () => void;
};

export const convertToPopupDateString = (dateString: string): string => {
  const parsedDate = new Date(dateString);

  if (isNaN(parsedDate.getTime())) {
    return "";
  }

  return new Intl.DateTimeFormat(window.navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
  }).format(parsedDate);
};

const CarrierFilterLabels = (props: CarrierFilterLabelsProps) => {
  const username = useUsername();

  const resetField = (partialFilter: Partial<CarriersFilter>) => {
    const { sortField, dir } = getDefaultCarriersFilter();

    const shouldResetSort =
      Object.keys(partialFilter).includes("capacityOrigin");

    props.resetQuickCapacity();
    props.setCarriersFilter({
      ...props.carriersFilter,
      ...partialFilter,
      ...(shouldResetSort && { sortField, dir }),
    });
  };

  return (
    <div className={styles.labelsContainer}>
      <div className={styles.filterTitle}>Filters:</div>

      {props.carriersFilter.search ? (
        <CarrierFilterLabelPopup
          labelText={`Search: ${props.carriersFilter.search.trim()}`}
          title="Remove Search"
          resetField={() => resetField({ search: "" })}
        >
          {props.carriersFilter.search.trim()}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.myFavoriteCarriers ? (
        <CarrierFilterLabelPopup
          labelText="My Favorite Carriers"
          title="Remove My Favorite Carriers"
          resetField={() => resetField({ myFavoriteCarriers: false })}
        >
          My Favorite Carriers
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.statuses.length ? (
        <CarrierFilterLabelPopup
          labelText="Status"
          title="Remove Statuses"
          resetField={() => resetField({ statuses: [] })}
        >
          {props.carriersFilter.statuses.map((status) => (
            <div key={status}>
              <Icon name="circle" color={statusToColorMap[status]} /> {status}
            </div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.domicileStates.length ? (
        <CarrierFilterLabelPopup
          labelText="Domicile State"
          title="Remove Domicile States"
          resetField={() => resetField({ domicileStates: [] })}
        >
          {props.carriersFilter.domicileStates.map((domicileState) => {
            const [country, state] = domicileState.split(",");

            return (
              <div key={domicileState}>
                <Flag name={country.toLowerCase() as FlagNameValues} />
                {state}
              </div>
            );
          })}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.domicileCities.length ? (
        <CarrierFilterLabelPopup
          labelText="Domicile City"
          title="Remove Domicile Cities"
          resetField={() => resetField({ domicileCities: [] })}
        >
          {props.carriersFilter.domicileCities.map((domicileCity) => (
            <div key={domicileCity}>{domicileCity}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.myCarriers ||
      props.carriersFilter.unownedCarriers ||
      props.carriersFilter.accountOwners.length ? (
        <CarrierFilterLabelPopup
          labelText="Account Owner"
          title="Remove Account Owners"
          resetField={() =>
            resetField({
              myCarriers: false,
              unownedCarriers: false,
              accountOwners: [],
            })
          }
        >
          {props.carriersFilter.myCarriers && (
            <div>
              <Icon name="user circle" />{" "}
              {username
                ? `${defaultAccountOwners.user} (${username})`
                : defaultAccountOwners.user}
            </div>
          )}
          {props.carriersFilter.unownedCarriers && (
            <div>
              <Icon name="ban" /> {defaultAccountOwners.none}
            </div>
          )}
          {props.carriersFilter.accountOwners.map((accountOwner) => (
            <div key={accountOwner.key}>{accountOwner.text}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.classifications.length ? (
        <CarrierFilterLabelPopup
          labelText="Classification"
          title="Remove Classifications"
          resetField={() => resetField({ classifications: [] })}
        >
          {props.carriersFilter.classifications.map((classification) => (
            <div key={classification}>
              {formatClassification(classification)}
            </div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.equipmentTypes.length ? (
        <CarrierFilterLabelPopup
          labelText="Equipment"
          title="Remove Equipment"
          resetField={() => resetField({ equipmentTypes: [] })}
        >
          {props.carriersFilter.equipmentTypes.map((equipmentType) => (
            <div key={equipmentType.key}>{equipmentType.text}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.trailerTypes.length ? (
        <CarrierFilterLabelPopup
          labelText="Trailer Type"
          title="Remove Trailer Types"
          resetField={() => resetField({ trailerTypes: [] })}
        >
          {props.carriersFilter.trailerTypes.map((trailerType) => (
            <div key={trailerType.key}>{trailerType.text}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.preferredServiceAreas.length ? (
        <CarrierFilterLabelPopup
          labelText="Preferred Area"
          title="Remove Preferred Areas"
          resetField={() => resetField({ preferredServiceAreas: [] })}
        >
          {props.carriersFilter.preferredServiceAreas.map((preferredArea) => {
            const [country, state] = preferredArea.split(",");

            return (
              <div key={preferredArea}>
                <Flag name={country.toLowerCase() as FlagNameValues} />
                {state}
              </div>
            );
          })}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.capacityPickupDate ? (
        <CarrierFilterLabelPopup
          labelText="Capacity Pickup Date"
          title="Remove Capacity Pickup Date"
          resetField={() => resetField({ capacityPickupDate: "" })}
        >
          {convertToPopupDateString(props.carriersFilter.capacityPickupDate)}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.capacityOrigin ? (
        <CarrierFilterLabelPopup
          labelText="Capacity Origin"
          title="Remove Capacity Origin"
          resetField={() => resetField({ capacityOrigin: "" })}
        >
          {props.carriersFilter.capacityOrigin}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.capacityDestination ? (
        <CarrierFilterLabelPopup
          labelText="Capacity Destination"
          title="Remove Capacity Destination"
          resetField={() => resetField({ capacityDestination: "" })}
        >
          {props.carriersFilter.capacityDestination}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.attributes.length ? (
        <CarrierFilterLabelPopup
          labelText="Attributes"
          title="Remove Attributes"
          resetField={() => resetField({ attributes: [] })}
        >
          {props.carriersFilter.attributes.map((attribute) => (
            <div key={attribute.key}>{attribute.text}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}
    </div>
  );
};

export default CarrierFilterLabels;
