import { useMemo } from "react";
import { AUTH_CLIENT_ID } from "../constants";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/store";

type UserRolesResponse = {
  userRoles: string[];
  isAdministrator: boolean;
  isReadOnlyEmployee: boolean;
  isEditableEmployee: boolean;
  isRoutingGuideEmployee: boolean;
};

const useUserRoles = (): UserRolesResponse => {
  const user = useSelector((state: RootState) => state.oidc.user);

  return useMemo(() => {
    const roles: string = user?.profile.roles ?? "";

    const userRoles = roles
      .split(",")
      .map((role) => role.split(":"))
      .filter(([app, _]) => app === AUTH_CLIENT_ID)
      .map(([_, role]) => role);

    return {
      userRoles,
      isAdministrator: userRoles.includes("Administrator"),
      isReadOnlyEmployee: userRoles.includes("ReadonlyEmployee"),
      isEditableEmployee: userRoles.includes("EditableEmployee"),
      isRoutingGuideEmployee: userRoles.includes("RoutingGuideEmployee"),
    };
  }, [user]);
};

export default useUserRoles;