import { useEffect, useState, Suspense, useCallback } from "react";
import axios from "axios";

import Layout from "hoc/Layout";
import Router from "./routes/Router";
import Loader from "./components/Loader";

import withErrorHandler from "./hoc/withErrorHandler";
import useUserRoles from "shared/hooks/useUserRoles";

import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/store/actions";
import { RootState } from "redux/store/store";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const App = () => {
  const [isReloaded, setIsReloaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const user = useSelector((state: RootState) => state.oidc.user);
  const dispatch = useDispatch();

  const { userRoles } = useUserRoles();

  const saveUserRoles = useCallback(
    (roles: string[]) => {
      dispatch(actions.saveUserRoles(roles?.join(",")));
    },
    [dispatch]
  );

  useEffect(() => {
    if (user && !isLoaded) {
      saveUserRoles(userRoles);
      setIsLoaded(true);
    }
  }, [user, isLoaded, userRoles, saveUserRoles]);

  useEffect(() => {
    if (user && user.profile && user.profile.roles && !isReloaded && isLoaded) {
      const currentRoles = userRoles.join(",");
      const previousRoles = localStorage.getItem("userRoles") || "";

      if (currentRoles !== previousRoles) {
        setIsReloaded(true);

        if (caches) {
          caches.keys().then((names) => {
            for (const name of names) caches.delete(name);
          });
        }

        localStorage.setItem("userRoles", currentRoles);
        window.location.reload();
      }
    }
  }, [user, userRoles, isReloaded, isLoaded]);

  if (!user) {
    return <Layout>Waiting for auth...</Layout>;
  }

  return (
    <Layout>
      <Suspense
        fallback={
          <div className="app-container-loader">
            <Loader />
          </div>
        }
      >
        <Router />
      </Suspense>
    </Layout>
  );
};

export default withErrorHandler(App, axios);
